<ng-template #loading>
  <app-loader-spinner-general
    [loadingType]="LoadingType.INPUT"
  ></app-loader-spinner-general>
</ng-template>

<div
  class="table-wrapper"
  *ngIf="assignment$ | async as assignment; else loading"
>
  <table>
    <thead *ngIf="isFirst">
      <tr>
        <th class="text-base font-semibold !px-3">
          {{ "LABEL.NAME" | translate }}
        </th>
        <th class="text-base font-semibold !px-3">
          {{ "GENERALS.ORDER-OFFER-FROM-TO" | translate }}
        </th>
        <th class="text-base font-semibold !px-3">
          {{ "GENERALS.ORDER-OFFER-PERIOD" | translate }}
        </th>
        <th class="ta-r"></th>
        <th class="ta-r"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <ng-container
          *ngIf="assignment.assignment; let assignment; else: noAssignment"
        >
          <td>
            <div class="w-full">{{ assignment.expat_name }}</div>
            <div
              class="w-full text-secondary text-xs"
              *ngIf="orderExpat.dependent_id"
            >
              ({{ "Case is for dependent" | translate }})
            </div>
          </td>
          <td>
            <div>
              {{ assignment.home_city }},
              {{ assignment.home_country_data?.name }}/
            </div>
            <div>
              {{ assignment.host_city }},
              {{ assignment.host_country_data?.name }}
            </div>
          </td>
          <td>
            {{ assignment.start_date | date : "dd/MM/yyyy" }} -
            {{ assignment.end_date | date : "dd/MM/yyyy" }}
          </td>
          <td class="text-right">
            <app-open-case-button
              *ngIf="order.accepted_on"
              [order]="order"
              [orderItem]="orderItem"
              [orderExpat]="orderExpat"
              (caseCreated)="handleCaseCreated()"
            >
            </app-open-case-button>
          </td>
          <td class="ta-r">
            <div
              toggle-action-table
              class="table-action-wrapper"
              *ngIf="
                !orderExpat?.case_id ||
                orderExpat.case_status === CaseStatusEnum.CANCELLED
              "
            >
              <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
                <mat-icon class="more-vert-icon">more_vert</mat-icon>
              </button>
              <mat-menu #actionsMenu="matMenu">
                <app-open-case-button
                  *ngIf="order.accepted_on"
                  [order]="order"
                  [orderItem]="orderItem"
                  [orderExpat]="orderExpat"
                  [isMatMenu]="true"
                  (caseCreated)="handleCaseCreated()"
                >
                </app-open-case-button>

                <button mat-menu-item (click)="deleteExpat()">
                  {{ "delete" | translate | uppercase }}
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <ng-template #noAssignment>
          <td>N/A</td>
          <td>N/A</td>
          <td>
            <!--
              commented until we are allowed to add assignments for individual expats
              <button class="btn orange-bg" (click)="openAssignmentDialog()">
              <span>
                {{ "GENERALS.ADD-ASSIGNMENT" | translate }}
              </span>
            </button> -->
          </td>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>
